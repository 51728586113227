const createAddressDataObjectForPickup = (shipment) => {
    let address = null;
    let lat = null;
    let lng = null;

    if (shipment.InternPickUp && (shipment.InternPickUpStreet && shipment.InternPickUpStreetNumber)) {
        let addressParts = [];
        if (shipment.InternPickUpStreet) {
            addressParts.push(shipment.InternPickUpStreet.trim());
        }
        if (shipment.InternPickUpStreetNumber) {
            addressParts.push(shipment.InternPickUpStreetNumber.trim());
        }
        if (shipment.InternPickUpCity) {
            addressParts.push(shipment.InternPickUpCity.trim());
        }
        address = addressParts.join(' ');
    } else {
        if (shipment.LongitudeO !== null && shipment.LatitudeO !== null) {
            lat = parseFloat(shipment.LatitudeO);
            lng = parseFloat(shipment.LongitudeO);
        } else if ((shipment.UlicaO && shipment.UlicaO.length > 0) || (shipment.ObecO && shipment.ObecO.length > 0) || (shipment.CisloO && shipment.CisloO.length > 0)) {
            let addressParts = [];
            if (shipment.UlicaO && shipment.UlicaO.length > 0) {
                addressParts.push(shipment.UlicaO.trim());
            }
            if (shipment.CisloO && shipment.CisloO.length > 0) {
                addressParts.push(shipment.CisloO.trim());
            }
            if (shipment.ObecO && shipment.ObecO.length > 0) {
                addressParts.push(shipment.ObecO.trim());
            }
            address = addressParts.join(' ');
        }
    }

    if (address === null && (lat === null || lng === null)) {
        return { address: null, lat: null, lng: null };
    }

    return { address: address, lat: lat, lng: lng };
};

const createAddressDataObjectForDelivery = (shipment) => {
    let address = null;
    let lat = null;
    let lng = null;

    if (shipment.LongitudeD !== null && shipment.LatitudeD !== null) { // Check for null explicitly for both
        lat = parseFloat(shipment.LatitudeD);
        lng = parseFloat(shipment.LongitudeD);
    }

    if (shipment.UlicaD && (shipment.UlicaD.length > 0 || (shipment.ObecD && shipment.ObecD.length > 0) || (shipment.CisloD && shipment.CisloD.length > 0))) {
        let addressParts = [];
        if (shipment.UlicaD && shipment.UlicaD.length > 0) {
            addressParts.push(shipment.UlicaD.trim());
        }
        if (shipment.CisloD && shipment.CisloD.length > 0) {
            addressParts.push(shipment.CisloD.trim());
        }
        if (shipment.ObecD && shipment.ObecD.length > 0) {
            addressParts.push(shipment.ObecD.trim());
        }
        address = addressParts.join(' ');
    }

    if (address === null && (lat === null || lng === null)) {
        return { address: null, lat: null, lng: null };
    }

    return { address: address, lat: lat, lng: lng };
};

export {
    createAddressDataObjectForDelivery,
    createAddressDataObjectForPickup,
}
