import {isInBox, removeAccents, waitingToDeliveryToBox} from "../../utils";
import {checkSearch, isMine, wasDelivered, wasPickupByMe} from "../../pages/shipmentsHelper";

const secondFilter = (shipment, secondFilter) => {
    const isZS = isZdravaStrava(shipment.PayerCompany.Firma_Kod);

    if ((!secondFilter.zs && isZS)) {
        return false;
    }

    return true;
}

const filterShipment = (items, filter, user) => {
    let isMyDeliveredFilter;
    let isMyToDeliveryFilter;
    let isMyFilter;
    let isSearch = filter.search !== '';

    isMyDeliveredFilter = filter.shipingFilter === 'myDelivered'; //ok
    isMyToDeliveryFilter = filter.shipingFilter === 'myToDelivery'; // ok
    isMyFilter = filter.shipingFilter === 'my' || isMyDeliveredFilter || isMyToDeliveryFilter;

    const search = removeAccents(filter.search.toLowerCase());
    return items?.filter(shipment => {
        const wasDeliveredShipment = wasDelivered(shipment, user.kod);
        const wasPickupByMeShipment = wasPickupByMe(shipment, user.kod);
        const toDelivery = !wasDeliveredShipment && isMine(shipment, user.kod);
        const isShipmentInBox = isInBox(shipment);

        if (isMyFilter && (isMine(shipment, user.kod) || wasPickupByMeShipment)) {
            if (
                (
                    isMyDeliveredFilter && (wasDeliveredShipment || (!isMine(shipment, user.kod) && wasPickupByMeShipment) || (!shipment.LastExchange?.Canceled && shipment.LastExchange?.Delivered && shipment.LastExchange?.From.Kurier_Kod === user.kod) || isShipmentInBox)
                ) || //delivered
                (isMyToDeliveryFilter && toDelivery) || //to delivery
                (isMyToDeliveryFilter && toDelivery && !isShipmentInBox) //to delivery
            ) {
                return isSearch ? checkSearch(shipment, search) : true;
            }

            return false;
        }

        return false;
    });
};

const filterShipmentSecond = (items, filterSecond) => items?.filter(shipment => secondFilter(shipment, filterSecond));

const filterFlagShipments = (items) => {
    let normalShipment = [];
    let toDepot = [];
    let unsuccessfulDelivery = [];
    let deliveryToBox = [];

    items.forEach((item) => {
        if (item.NaDepo) {
            toDepot.push(item);
        } else if (item.NeuspesneDorucenieFlag) {
            unsuccessfulDelivery.push(item);
        } else if (waitingToDeliveryToBox(item)) {
            deliveryToBox.push(item);
        } else {
            normalShipment.push(item);
        }
    });

    return [normalShipment, toDepot, unsuccessfulDelivery, deliveryToBox];
};

const filterShipmentsPointWithoutDeliveryGeoPosition = (items) => {
    let normalShipmentPoints = [];
    let withoutGeoPosition = [];

    items.forEach((point) => {
        if (!point.shipment.LatitudeD || !point.shipment.LongitudeD) {
            withoutGeoPosition.push(point);
        } else {
            normalShipmentPoints.push(point);
        }
    });

    return [normalShipmentPoints, withoutGeoPosition];
};

const isMallPayer = (payerCode) => {
    return String(payerCode) === process.env.REACT_APP_MALL_COMPANY_CODE;
}

const isZdravaStrava = (payerCode) => {
    return String(payerCode) === process.env.REACT_APP_ZDRAVA_STRAVA_COMPANY_CODE;
}

export {
    secondFilter,
    filterShipmentSecond,
    filterFlagShipments,
    filterShipment,
    isMallPayer,
    isZdravaStrava,
    filterShipmentsPointWithoutDeliveryGeoPosition
}
