import React, {useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {SwipeableDrawer as Drawer} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Router} from '@gatsbyjs/reach-router';
import Shipment from '../pages/shipment/shipment';
import Shipments from '../pages/shipments';
import Scanner from '../pages/scanner';
import ScannerSearchNote from '../pages/scannerSearchNote';
import ScannerDetail from '../pages/scannerDetail';
import TopBar from '../components/TopBar';
import LeftMenu from '../components/main/LeftMenu';
import {default as MainTopBar} from "../components/main/TopBar";
import {default as RouteLeftMenu} from "../components/route/LeftMenu";
import {default as RouteTopBar} from "../components/route/TopBar";
import 'prevent-pull-refresh';
import Map from "./map/map";
import GlobalStyles from "../ui/globalStyles";
import ScannerSelect from "./scannerSelect";
import RouteStep1 from "./route/step1";
import RouteStep2 from "./route/step2";
import RouteStep3 from "./route/step3";
import Shifts from "./shifts/shifts";
import ScannerInput from "./scannerInput";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: theme.custom.main.drawerWidth,
            flexShrink: 0,
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: theme.custom.main.drawerWidth,
    },
    content: {
        flexGrow: 1,
        minWidth: 0,
    },
    toRight: {
        marginLeft: 'auto'
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
    refreshButton: {
        padding: 4,
    },
}));

export default function Pages(props) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const shipmentsRef = React.useRef(null);

    const handleReloadClick = () => {
        shipmentsRef.current.stopPolling();
        shipmentsRef.current.refetch();
        shipmentsRef.current.startPolling();
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const Menu = () => (
        <Router primary={false}>
            <RouteLeftMenu path="route/*" />
            <LeftMenu path="/*" handleReloadClick={handleReloadClick}/>
        </Router>
    );

    const TopBarWrapper = ({handleDrawerToggle}) => (
        <div>
            <TopBar handleDrawerToggle={handleDrawerToggle} showFilter={true}>
                <MainTopBar handleReloadClick={handleReloadClick}/>
            </TopBar>
        </div>
    );

    const TopBarRouteWrapper = ({handleDrawerToggle}) => (
        <div>
            <TopBar handleDrawerToggle={handleDrawerToggle} showFilter={false}>
                <RouteTopBar/>
            </TopBar>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <GlobalStyles />

            <Router primary={false}>
                <TopBarRouteWrapper path="route/*" handleDrawerToggle={handleDrawerToggle} />
                <TopBarWrapper path="/" handleDrawerToggle={handleDrawerToggle} />
            </Router>

            <nav className={classes.drawer} aria-label="filter">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        disableBackdropTransition={true}
                        onOpen={()=>{}}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <Menu/>
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                        onOpen={()=>{}}
                        onClose={()=>{}}
                    >
                        <Menu/>
                    </Drawer>
                </Hidden>
            </nav>

            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div style={{'minHeight': '34px'}} />
                <Router>
                    <Shipments path="/" ref={shipmentsRef}/>
                    <Shipment path="shipment/:shipmentId/*" handleDrawerToggle={handleDrawerToggle}/>
                    <Shipment path="map/:shipmentId/*" handleDrawerToggle={handleDrawerToggle}/>
                    <ScannerSearchNote path="scanner/search/note/:note/" handleDrawerToggle={handleDrawerToggle} />
                    <ScannerDetail path="scannernote/:shipmentId/:note/*" handleDrawerToggle={handleDrawerToggle} />
                    <ScannerDetail path="scanner/:shipmentId/*" handleDrawerToggle={handleDrawerToggle} />
                    <ScannerInput path="scanner-input" />
                    <Scanner path="scanner" />
                    <ScannerSelect path="scannerselect" />
                    <RouteStep1 path="route/step1" />
                    <RouteStep2 path="route/step2" />
                    <RouteStep3 path="route/step3" />
                    <Shifts path="shifts" />
                    <Map path="map" />
                </Router>
            </main>
        </div>

    );
}
