import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StyledP from '../components/shipments/styledP';
import {ShippingTile} from "./index";
import ArrowForwardSharpIcon from "@material-ui/icons/ArrowForwardSharp";
import {waitingToDeliveryToBox, wasDelivery, wasPickUp} from "../utils";
import A from '../components/shipments/a';
import Wrapper from '../components/shipments/wrapper';
import clsx from 'clsx';
import ScrollManager from "../utils/ScrollManager";
import ShipmentFlags from "./shipments/ShipmentFlags";
import {isMine} from "../pages/shipmentsHelper";
import Flag from "./flags/flag";
import useLongPress from "../utils/react/hooks/useLongPress";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    openIcon: {
        position: 'absolute',
        right: '25px',
        top: '-7px',
        fontSize: '4em',
    },
    icon: {
        fontSize: '1em',
    },
    grouped: {
        backgroundColor: '#eee',
        boxShadow: 'inset 0px 0px 7px 2px rgba(140,140,140,1)',
        width: '100%',
    },
    selected: {
        border: '5px solid red',
    },
    disableClick: {
        '&': {
            pointerEvents: 'none'
        },
    },
    wrapper: {
        border: '2px solid black',
        width: '100%',
    },
    flag: {
        float: 'right',
        marginRight: 65,
    },
    floatReset: {
        clear: 'both',
    },
}));

export default function GroupedShipment({shipment, multiSelect, handleMultiSelectShipment, handleMultiSelectGroupShipment, selectedShipments, forDepot, user, onAddressClick}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleMultiSelect = () => {
        let ids = [shipment.Zasielka_Kod];
        Object.keys(shipment.grouped).forEach((key, index) => {
            ids.push(shipment.grouped[key].Zasielka_Kod);
        });
        handleMultiSelectGroupShipment(ids, !selected);
        setSelected(!selected);
    }

    const handleMultiSelectClick = event => {
        if (multiSelect && typeof event.target.parentNode.dataset.detailLink === "undefined") {
            handleMultiSelect();
        }
        event.stopPropagation();
    };

    const isSelectedGroup = selectedShipments.includes(shipment.Zasielka_Kod) && Object.keys(shipment.grouped).reduce((accumilator, key) => {
        return accumilator && selectedShipments.includes(shipment.grouped[key].Zasielka_Kod);
    });

    let companyText;
    let addressText;
    if (typeof forDepot !== 'undefined' && forDepot) {
        companyText = 'GO4';
        addressText =
            <Fragment>
                <ArrowForwardSharpIcon className={classes.icon}/>
                <A className={clsx(classes.href, classes.done)}
                   onClick={(event) => {
                       event.preventDefault();
                       onAddressClick({
                           address: 'Prievozská 34, Bratislava',
                       })
                   }}
                >
                    <b>
                        <div className={classes.street}>Prievozská 34</div>
                    </b>
                </A>
                <Flag className={classes.flag} depotFlag={shipment.NaDepo}/>
                <div className={classes.floatReset}></div>
            </Fragment>
    } else if (waitingToDeliveryToBox(shipment)) {
        companyText = shipment.LastActiveCreateReservation.box?.company?.name + ' - ' +  shipment.LastActiveCreateReservation.box.name;
        addressText =
            <Fragment>
                <ArrowForwardSharpIcon className={classes.icon}/>
                {shipment.LastActiveCreateReservation && (
                    <A className={clsx(classes.href, classes.done)}
                       onClick={(event) => {
                           event.preventDefault();
                           onAddressClick({
                               lat: shipment.LastActiveCreateReservation.box.location.latitude,
                               lng: shipment.LastActiveCreateReservation.box.location.longitude,
                           })
                       }}
                    >
                        <b>
                            <div className={classes.street}>{shipment.LastActiveCreateReservation.box.street}</div>
                        </b>
                    </A>
                )}
                {/*<Flag className={classes.flag} iso={shipment.StatDISO} city={shipment.ObecD} depotFlag={shipment.NaDepo} street={shipment.UlicaD} streetNumber={shipment.CisloD}/>*/}
                <div className={classes.floatReset}></div>
            </Fragment>
    } else if (wasPickUp(shipment)) {
        companyText = shipment.NazovD;
        addressText =
            <Fragment>
                <ArrowForwardSharpIcon className={classes.icon}/>
                {((shipment.UlicaD && shipment.UlicaD.length > 0) || (shipment.ObecD && shipment.ObecD.length > 0) || (shipment.CisloD && shipment.CisloD.length > 0)) && (
                    <A className={clsx(classes.href, classes.done)}
                       onClick={(event) => {
                           event.preventDefault();
                           onAddressClick({
                               address: shipment.UlicaD + ' ' + shipment.CisloD + ',' + shipment.ObecD,
                           })
                       }}
                    >
                        <b>
                            <div className={classes.street}>{shipment.UlicaD} {shipment.CisloD}</div>
                        </b>
                    </A>
                )}
                <Flag className={classes.flag} iso={shipment.StatDISO} city={shipment.ObecD} depotFlag={shipment.NaDepo} street={shipment.UlicaD} streetNumber={shipment.CisloD}/>
                <div className={classes.floatReset}></div>
            </Fragment>
    } else {
        companyText = shipment.NazovO;
        addressText =
            <Fragment>
                <ArrowForwardSharpIcon className={classes.icon}/>
                {shipment.UlicaO && shipment.UlicaO.length > 0 && shipment.ObecO && shipment.ObecO.length > 0 && shipment.CisloO && shipment.CisloO.length > 0 && (
                    <A
                        onClick={(event) => {
                            event.preventDefault();
                            onAddressClick({
                                address: shipment.UlicaO + ' ' + shipment.CisloO + ',' + shipment.ObecO,
                            })
                        }}
                        className={clsx(classes.href, classes.disableClick)}
                    >
                        <b>{shipment.UlicaO} {shipment.CisloO}</b>
                    </A>
                )}
                <Flag className={classes.flag} iso={shipment.StatOISO} city={shipment.ObecO} depotFlag={false} street={shipment.UlicaO} streetNumber={shipment.CisloO}/>
                <div className={classes.floatReset}></div>
            </Fragment>;
    }

    let isExpress = shipment.grouped.every(shipment => shipment.Flags.includes('e'));
    let isMineVar = shipment.grouped.every(shipment => isMine(shipment, user.kod));
    let wasNotPickedUp = shipment.grouped.every(shipment => !wasPickUp(shipment));
    let wasPickedUp = shipment.grouped.every(shipment => wasPickUp(shipment));
    let wasNotDelivered = shipment.grouped.every(shipment => !wasDelivery(shipment));

    const onLongPress = (event) => {
        event.preventDefault();
        event.stopPropagation();
        handleMultiSelect();
    };

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, handleClick, defaultOptions);

    return (
        <div className={classes.wrapper}>
            <Wrapper isSelected={multiSelect && isSelectedGroup} onClick={multiSelect ? handleMultiSelectClick : undefined}>
                <ScrollManager scrollKey={shipment.Zasielka_Kod}/>
                <Box>
                    <ShipmentFlags showPickup={isMineVar && wasPickedUp && wasNotDelivered} showMine={isMineVar && wasNotPickedUp} showExpress={isExpress} />
                    <StyledP
                        className={classes.companyLine}>{companyText} ({++Object.keys(shipment.grouped).length})</StyledP>
                    <StyledP>
                        {addressText}
                    </StyledP>
                    <a {...longPressEvent} data-detail-link="true">
                        {open ? <ExpandLess className={classes.openIcon}/> : <ExpandMore className={classes.openIcon}/>}
                    </a>
                </Box>
            </Wrapper>
            <Collapse className={classes.grouped} in={open} timeout="auto" unmountOnExit>
                <ShippingTile
                    key={shipment.Zasielka_Kod}
                    shipment={shipment}
                    multiSelect={multiSelect}
                    isSelected={selectedShipments.includes(shipment.Zasielka_Kod)}
                    handleMultiSelectShipment={handleMultiSelectShipment}
                    onAddressClick={onAddressClick}
                />
                {shipment.grouped &&
                Object.keys(shipment.grouped).map((key, index) => (
                    <ShippingTile
                        key={shipment.grouped[key].Zasielka_Kod}
                        shipment={shipment.grouped[key]}
                        multiSelect={multiSelect}
                        isSelected={selectedShipments.includes(shipment.grouped[key].Zasielka_Kod)}
                        handleMultiSelectShipment={handleMultiSelectShipment}
                        onAddressClick={onAddressClick}
                    />
                ))}
            </Collapse>
        </div>
    );
}
