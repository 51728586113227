import React, {forwardRef, useImperativeHandle, Fragment, useEffect} from 'react';
import {gql, useQuery} from '@apollo/client';
import {ShippingTile} from '../components';
import {format, startOfDay, subDays, endOfDay} from 'date-fns';
import Grid from '@material-ui/core/Grid';
import {loader} from "graphql.macro";
import GroupedShipment from "../components/GroupedShipment";
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MultiSelectPanel from "../components/shipments/MultiSelectPanel";
import {makeStyles} from "@material-ui/core/styles";
import {
    getSortCallbackClassic,
    updateFilterCount, updateSecondFilterCount,
} from './shipmentsHelper';
import clsx from 'clsx';
import Header from "../components/shipment/header";
import {groupedVar} from "../utils/apollo/reactiveVar/grouped";
import {cache} from "../links";
import {filterFlagShipments, filterShipment, filterShipmentSecond} from "../utils/shipments";
import {errorMessage} from "../utils/messages";
import {errorCodes} from "../utils/apollo/network/errorCodes";
import {useSnackbar} from "notistack";
import {useGeolocated} from "react-geolocated";
import MapSelector from "../components/mapSelector/mapSelector";

const GET_FILTER = loader('../gql/query/client/filter.graphql');
const GET_SHIPMENTS = loader('../gql/query/shipments.graphql');
const POLL_INTERVAL = parseInt(process.env.REACT_APP_POLL_INTERVAL);

const useStyles = makeStyles(theme => ({
    grid: {
        paddingBottom: 105,
    },
    grouped: {
        backgroundColor: "#ffedd9",
    },
    icon: {
        verticalAlign: 'middle',
    },
    divider: {
        width: '100%',
    },
    gridCell: {
        margin: '1px 0',
    },
}));

function ShipmentsClassic({dataLocal}, ref) {
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [isMapSelectorOpen, setIsMapSelectorOpen] = React.useState(false);
    const [selectedLocation, setSelectedLocation] = React.useState({ address: null, lat: null, lng: null });

    let from = startOfDay(subDays(new Date(), process.env.REACT_APP_HISTORY_DATE));
    let to = endOfDay(new Date());

    const {coords, isGeolocationAvailable, isGeolocationEnabled} =
        useGeolocated({
            positionOptions: {
                maximumAge: 300000,
                enableHighAccuracy: true,
                timeout: 10000,
            },
            watchLocationPermissionChange: true,
            //userDecisionTimeout: 5000,
        });

    const {data, loading, refetch, startPolling, stopPolling, client} = useQuery(GET_SHIPMENTS, {
        variables: {
            fromDate: format(from, 'yyyy-MM-dd'),
            toDate: format(to, 'yyyy-MM-dd'),
        },
        notifyOnNetworkStatusChange: true,
        onError: (error) => {
            for (let err of error.graphQLErrors) {
                if (err.extensions.code === errorCodes.NOT_PAID_COD) {
                    errorMessage('Zablokované načítavanie zasielok kvôli nezaplateným dobierkam. Po zaplatený dobierok bude prístup odblokovaný.', enqueueSnackbar, closeSnackbar)
                }
            }
        },
    });

    let geoLocation = null;
    if (isGeolocationEnabled && isGeolocationAvailable && coords && dataLocal.filter.shipingFilter === 'not') {
        geoLocation = {
            latitude: coords.latitude,
            longitude: coords.longitude,
        }
    }

    if (loading !== dataLocal.loading) {
        client.writeQuery({
            query: gql`
                {
                    loading @client
                }
            `,
            data: {
                loading
            },
        })
    }

    // second parameter tell if you want add or remove shipments from multiselect
    const handleMultiSelectGroupShipment = (ids, add) => {
        if (!Array.isArray(ids)) {
            return;
        }

        let selected = dataLocal.filter.multiSelectedShipments;
        ids.forEach(item => {
            if (!add) { //remove
                const filtered = selected.filter(value => value !== item);
                selected = [...filtered];
            } else { //add
                selected = [...selected, item];
            }
        });

        client.writeQuery({
            query: GET_FILTER,
            data: {
                filter: {
                    ...dataLocal.filter,
                    multiSelect: selected.length !== 0,
                    multiSelectedShipments: [...selected],
                    __typename: 'Filter',
                }
            },
        });
        return;
    };

    const handleDeleteFromMultiselect = async (id) => {
        cache.modify({
            fields: {
                filter(existingCommentRefs = [], { readField }) {
                    const filtered = existingCommentRefs.multiSelectedShipments.filter(value => value !== id);

                    return {
                        ...existingCommentRefs,
                        multiSelect: filtered.length !== 0,
                        multiSelectedShipments:filtered,
                    };
                }
            }
        });
    };

    const handleMultiSelectShipment = async (id) => {
        if (dataLocal.filter.multiSelectedShipments.includes(id)) { //removing
            await handleDeleteFromMultiselect(id);
        } else { //adding
            client.writeQuery({
                query: GET_FILTER,
                data: {
                    filter: {
                        ...dataLocal.filter,
                        multiSelect: true,
                        multiSelectedShipments: [...dataLocal?.filter.multiSelectedShipments, id],
                        __typename: 'Filter',
                    }
                },
            });
        }
    };

    const handleAddressClick = (addressData) => {
        setSelectedLocation(addressData);
        setIsMapSelectorOpen(true);
    };

    const handleMapSelectorClose = () => {
        setIsMapSelectorOpen(false);
        setSelectedLocation({ address: null, lat: null, lng: null });
    };

    // leave it here for future, could be quick fix in problem with remote single shipment from multiselect after send action
/*    const handleResetMultiSelect = async () => {
        await client.writeQuery({
            query: GET_FILTER,
            data: {
                filter: {
                    ...dataLocal.filter,
                    multiSelect: false,
                    multiSelectedShipments: [],
                    __typename: 'Filter',
                }
            },
        });
    };*/

    startPolling(POLL_INTERVAL);

    useImperativeHandle(ref, () => ({
        refetch: () => {
            refetch();
        },
        startPolling: () => {
            startPolling(POLL_INTERVAL);
        },
        stopPolling: () => {
            stopPolling();
        },
    }));

    let filtered = [];

    if (typeof data !== "undefined" && data.shipments != null) {
        filtered = filterShipment(data.shipments, dataLocal.filter, dataLocal.user);
        updateSecondFilterCount(client, filtered, dataLocal.user);
        filtered = filterShipmentSecond(filtered, dataLocal.filterSecond);
        filtered.sort(getSortCallbackClassic(dataLocal.filter.order));
    }

    useEffect(() => {
        if (typeof data !== "undefined" && data.shipments != null) {
            updateFilterCount(client, data.shipments, dataLocal.user, dataLocal.filter);
        }
    });

    if (filtered && dataLocal.filter.group) {
        let shipmentByKey = {};
        for (let index = 0; index < filtered.length; index++) {
            const key = filtered[index]['key'];
            if (shipmentByKey[key]) {
                shipmentByKey[key].grouped.push(filtered[index]);
                delete filtered[index]; // remove shipment from array filtered array
            } else {
                shipmentByKey[key] = {
                    mainShipment: filtered[index],
                    grouped: [],
                };
            }
        }

        let shipmentByCode = {};
        for (const key in shipmentByKey) {
            shipmentByCode[shipmentByKey[key].mainShipment.id] = shipmentByKey[key].grouped;
        }
        groupedVar(shipmentByCode);
    }

    let [normalShipment, toDepot, unsuccesfullDelivery, deliveryToBox] = filterFlagShipments(filtered);

    return (
        <Fragment>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                className={clsx(dataLocal.filter.multiSelect && classes.grid)}
            >
                {normalShipment &&
                    normalShipment.map(shipment => (
                        <Grid className={clsx(classes.gridCell,dataLocal.filter.group && shipment.grouped && classes.grouped)} container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={2} key={shipment.id}>
                            {dataLocal.filter.group && shipment.grouped ? (
                                <GroupedShipment
                                    shipment={shipment}
                                    selectedShipments={dataLocal?.filter.multiSelectedShipments}

                                    multiSelect={dataLocal.filter.multiSelect}
                                    handleMultiSelectShipment={handleMultiSelectShipment}
                                    handleMultiSelectGroupShipment={handleMultiSelectGroupShipment}
                                    user={dataLocal.user}
                                    onAddressClick={handleAddressClick}
                                />
                            ) : (
                                <ShippingTile
                                    shipment={shipment}
                                    key={shipment.id}

                                    multiSelect={dataLocal.filter.multiSelect}
                                    isSelected={dataLocal?.filter.multiSelectedShipments.includes(shipment.Zasielka_Kod)}
                                    handleMultiSelectShipment={handleMultiSelectShipment}
                                    geoLocation={geoLocation}
                                    onAddressClick={handleAddressClick}
                                />
                            )}
                            <Divider className={classes.divider}/>
                        </Grid>
                    ))
                }


                {deliveryToBox && deliveryToBox.length > 0 && (
                    <Fragment>
                        <Grid className={classes.toDepotTitle} container item xs={12} spacing={3}>
                            <Header>
                                <Box mt={11} mb={3}>
                                    Doručit do balikoboxu <ArrowDownwardIcon className={classes.icon}/>
                                </Box>
                            </Header>
                        </Grid>

                        {
                            deliveryToBox.map(shipment => (
                                <Grid className={clsx(classes.gridCell,dataLocal.filter.group && shipment.grouped && classes.grouped)} container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={1} key={shipment.id}>
                                    {dataLocal.filter.group && shipment.grouped ? (
                                        <GroupedShipment
                                            shipment={shipment}
                                            selectedShipments={dataLocal?.filter.multiSelectedShipments}

                                            multiSelect={dataLocal.filter.multiSelect}
                                            handleMultiSelectShipment={handleMultiSelectShipment}
                                            handleMultiSelectGroupShipment={handleMultiSelectGroupShipment}
                                            user={dataLocal.user}
                                            onAddressClick={handleAddressClick}
                                        />
                                    ) : (
                                        <ShippingTile
                                            shipment={shipment}
                                            key={shipment.id}

                                            multiSelect={dataLocal.filter.multiSelect}
                                            isSelected={dataLocal?.filter.multiSelectedShipments.includes(shipment.Zasielka_Kod)}
                                            handleMultiSelectShipment={handleMultiSelectShipment}
                                            onAddressClick={handleAddressClick}
                                        />
                                    )}
                                </Grid>
                            ))
                        }
                    </Fragment>
                )}


                {unsuccesfullDelivery && unsuccesfullDelivery.length > 0 && (
                    <Fragment>
                        <Grid className={classes.toDepotTitle} container item xs={12} spacing={3}>
                            <Header>
                                <Box mt={11} mb={3}>
                                    Neúspešný pokus o doručenie <ArrowDownwardIcon className={classes.icon}/>
                                </Box>
                            </Header>
                        </Grid>

                        {
                            unsuccesfullDelivery.map(shipment => (
                                <Grid className={clsx(classes.gridCell,dataLocal.filter.group && shipment.grouped && classes.grouped)} container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={1} key={shipment.id}>
                                    {dataLocal.filter.group && shipment.grouped ? (
                                        <GroupedShipment
                                            shipment={shipment}
                                            selectedShipments={dataLocal?.filter.multiSelectedShipments}

                                            multiSelect={dataLocal.filter.multiSelect}
                                            handleMultiSelectShipment={handleMultiSelectShipment}
                                            handleMultiSelectGroupShipment={handleMultiSelectGroupShipment}
                                            user={dataLocal.user}
                                        />
                                    ) : (
                                        <ShippingTile
                                            shipment={shipment}
                                            key={shipment.id}

                                            multiSelect={dataLocal.filter.multiSelect}
                                            isSelected={dataLocal?.filter.multiSelectedShipments.includes(shipment.Zasielka_Kod)}
                                            handleMultiSelectShipment={handleMultiSelectShipment}
                                        />
                                    )}
                                </Grid>
                            ))
                        }
                    </Fragment>
                )}


                {toDepot && toDepot.length > 0 && (
                    <Fragment>
                        <Grid className={classes.toDepotTitle} container item xs={12} spacing={3}>
                                <Header>
                                    <Box mt={11} mb={3}>
                                        Na depo <ArrowDownwardIcon className={classes.icon}/>
                                    </Box>
                                </Header>
                        </Grid>

                        {
                            toDepot.map(shipment => (
                                <Grid className={clsx(classes.gridCell,dataLocal.filter.group && shipment.grouped && classes.grouped)} container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={1} key={shipment.id}>
                                    {dataLocal.filter.group && shipment.grouped ? (
                                        <GroupedShipment
                                            shipment={shipment}
                                            selectedShipments={dataLocal?.filter.multiSelectedShipments}

                                            multiSelect={dataLocal.filter.multiSelect}
                                            handleMultiSelectShipment={handleMultiSelectShipment}
                                            handleMultiSelectGroupShipment={handleMultiSelectGroupShipment}
                                            forDepot={1}
                                            user={dataLocal.user}
                                            onAddressClick={handleAddressClick}
                                        />
                                    ) : (
                                        <ShippingTile
                                            shipment={shipment}
                                            key={shipment.id}

                                            multiSelect={dataLocal.filter.multiSelect}
                                            isSelected={dataLocal?.filter.multiSelectedShipments.includes(shipment.Zasielka_Kod)}
                                            handleMultiSelectShipment={handleMultiSelectShipment}
                                            onAddressClick={handleAddressClick}
                                        />
                                    )}
                                </Grid>
                            ))
                        }
                    </Fragment>
                )}
            </Grid>

            {dataLocal.filter.multiSelect && (
                <MultiSelectPanel
                    selectedShipments={dataLocal?.filter.multiSelectedShipments}
                    removeFromMultiselect={handleDeleteFromMultiselect}
                />
            )}

            <MapSelector
                open={isMapSelectorOpen}
                onClose={handleMapSelectorClose}
                address={selectedLocation.address}
                lat={selectedLocation.lat}
                lng={selectedLocation.lng}
            />
        </Fragment>
    );
};

export default forwardRef(ShipmentsClassic);
