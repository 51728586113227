import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Line from "../shipment/line";
import QRCode from "react-qr-code";
import A from "../shipments/a";
import clsx from "clsx";
import MapSelector from "../mapSelector/mapSelector";

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: '3em',
        color: 'black',
    },
}));

export default function ShowBox({box, showPin, children}) {
    const classes = useStyles();
    const [isMapSelectorOpen, setIsMapSelectorOpen] = React.useState(false);
    const [selectedLocation, setSelectedLocation] = React.useState({ lat: null, lng: null });

    const handleAddressClick = (addressData) => {
        setSelectedLocation(addressData);
        setIsMapSelectorOpen(true);
    };

    const handleMapSelectorClose = () => {
        setIsMapSelectorOpen(false);
        setSelectedLocation({ lat: null, lng: null });
    };

    return (
        <Grid item xs={12}>
            <Line>
                <span>Obsadenosť: <b>{box.occupancy}%</b></span>
            </Line>

            {children}

            {showPin && (
                <Line>
                    <span>Pin na prihlásenie do boxu: <b>{box.dailyPin}</b></span>
                </Line>
            )}

            {showPin && (
                <Line>
                    <div style={{ background: 'white', maxWidth: 200, padding: '16px' }}>
                        <div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={box.dailyPin}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                </Line>
            )}

            <Line>
                <span>Názov: <b>{box.name}</b></span>
            </Line>
            <Line>
                <span>Adresa:&nbsp;
                    <A
                        className={clsx(classes.href, classes.done)}
                        onClick={(event) => {
                            event.preventDefault();
                            handleAddressClick({
                                lat: box.location.latitude,
                                lng: box.location.longitude,
                            });
                        }}
                    >
                        <b>{box.street} {box.city}</b>
                    </A>
                </span>
            </Line>
            <Line>
                <span>Popis: <b>{box.description}</b></span>
            </Line>

            <Line>
                {box.photoHref && (
                    <img src={box.photoHref} style={{
                        maxWidth: '100%',
                    }}/>
                )}
            </Line>

            <MapSelector
                open={isMapSelectorOpen}
                onClose={handleMapSelectorClose}
                lat={selectedLocation.lat}
                lng={selectedLocation.lng}
            />
        </Grid>
    );
}
