const calculateDistanceCosineKm = function calculateDistanceCosineKm(lat1, lon1, lat2, lon2) {
    // Radius of the Earth in meters
    const R = 6371;

    // Convert latitude and longitude from degrees to radians
    lat1 = lat1 * Math.PI / 180;
    lon1 = lon1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;

    // Calculate the distance using the Spherical Law of Cosines
    const distance = Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)) * R;

    return distance;
}

export {
    calculateDistanceCosineKm,
};
