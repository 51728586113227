import React, {forwardRef, useImperativeHandle, Fragment, useEffect} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {Loading, ShippingPointTitle} from '../components/';
import Types from '../datasource/shipmentPointsGraphQL';
import {format, startOfDay, subDays, endOfDay} from 'date-fns';
import Grid from '@material-ui/core/Grid';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {getSortCallbackSingle, updateFilterCount} from './shipmentsHelper';
import {makeStyles} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import {errorMessage, successMessage} from "../utils/messages";
import {createShipmentPoints, filterShipments} from "../utils/shipments/shipmentPoints";
import clsx from 'clsx';
import {errorCodes} from "../utils/apollo/network/errorCodes";
import MapSelector from "../components/mapSelector/mapSelector";

const GET_FILTER = loader('../gql/query/client/filter.graphql');
const SHIPMENTS_ORDER = loader('../gql/mutations/shipmentsOrder.graphql');
const GET_SHIPMENTS = loader('../gql/query/shipments.graphql');
const POLL_INTERVAL = parseInt(process.env.REACT_APP_POLL_INTERVAL);

const useStyles = makeStyles(theme => ({
    grid: {
        paddingBottom: 105,
    },
}));


function ShipmentsSingle({dataLocal}, ref) {
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {data: dataFilter} = useQuery(GET_FILTER);
    const [state, setState] = React.useState({
        disableDrag: false,
        draggingId: null,
        draggingPairId: null,
        draggingPairPosition: null,
        draggingPairType: null,
    });
    const [isMapSelectorOpen, setIsMapSelectorOpen] = React.useState(false);
    const [selectedLocation, setSelectedLocation] = React.useState({ address: null, lat: null, lng: null });
    const [sendShipmentOrder] = useMutation(SHIPMENTS_ORDER, {
        onError: error => errorMessage('Chyba pri ukladaní poradia: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: data => {
            successMessage('Poradie zásielok uložené.', enqueueSnackbar, closeSnackbar);
        },
    });

    let from = startOfDay(subDays(new Date(), process.env.REACT_APP_HISTORY_DATE));
    let to = endOfDay(new Date());
    const {data, loading, refetch, startPolling, stopPolling, client} = useQuery(GET_SHIPMENTS, {
        variables: {
            fromDate: format(from, 'yyyy-MM-dd'),
            toDate: format(to, 'yyyy-MM-dd'),
        },
        onError: (error) => {
            for (let err of error.graphQLErrors) {
                if (err.extensions.code === errorCodes.NOT_PAID_COD) {
                    errorMessage('Zablokované načítavanie zasielok kvôli nezaplateným dobierkam. Po zaplatený dobierok bude prístup odblokovaný.', enqueueSnackbar, closeSnackbar)
                }
            }
        },
    });

    startPolling(POLL_INTERVAL);

    useImperativeHandle(ref, () => ({
        refetch: () => {
            refetch();
        },
        startPolling: () => {
            startPolling(POLL_INTERVAL);
        },
        stopPolling: () => {
            stopPolling();
        },
    }));

    const findPairId = (id) => {
        const lastChar = id.slice(-1);
        const originalId = id.substring(0, id.length - 1);;

        if (lastChar === String(Types.TO_PICKUP)) {
            return originalId + String(Types.TO_DELIVERY);
        } else {
            return originalId + String(Types.TO_PICKUP);
        }
    }

    const sendOrder = items => {
        let i;
        let dataToSend = [];
        let dataOptimistic = [];
        for (i = 0; i < items.length; i++) {
            dataToSend.push({
                shipmentId: items[i].shipment['Zasielka_Kod'],
                type: items[i].type,
            });
            dataOptimistic.push({
                Orders: [{
                    Poradie: i+1,
                    Adresa_Typ: items[i].type,
                    id: items[i].id,
                    "__typename": "Order"
                }],
                "__typename": "Shipment",
            });
        }
        sendShipmentOrder({
            variables: {
                shipmentsOrder: dataToSend,
            },
            optimisticResponse: {
                shipmentsOrder: dataOptimistic
            },
        });
    };


    let filtered = [];
    if (typeof data !== "undefined") {
        filtered = filterShipments(createShipmentPoints(data.shipments), dataLocal.filter, dataLocal.user);
        filtered.sort(getSortCallbackSingle(dataLocal.filter.orderSingle));
    }

    const handleAddressClick = (addressData) => {
        setSelectedLocation(addressData);
        setIsMapSelectorOpen(true);
    };

    const handleMapSelectorClose = () => {
        setIsMapSelectorOpen(false);
        setSelectedLocation({ address: null, lat: null, lng: null });
    };

    const onBeforeDragStart = (object) => {
        const id = object.draggableId;
        const draggingPairId = findPairId(id);
        let draggingPairPosition = null;
        let draggingPairType = null;

        for (let i = 0; i < filtered.length; i++) {
            if (filtered[i].id === draggingPairId) {
                draggingPairPosition = filtered[i].order;
                draggingPairType = filtered[i].type;
            }
        }

        setState({...state,
            draggingId: id,
            draggingPairId: draggingPairId,
            draggingPairPosition: draggingPairPosition,
            draggingPairType: draggingPairType,
        });
    };

    const checkUnder = (draggableId) => {
        if (draggableId.slice(-1) === String(Types.TO_PICKUP)) {
            setState({...state, disableDrag: false});
        } else {
            setState({...state, disableDrag: true});
        }
    };

    const checkBehind = (draggableId) => {
        if (draggableId.slice(-1) === String(Types.TO_DELIVERY)) {
            setState({...state, disableDrag: false});
        } else {
            setState({...state, disableDrag: true});
        }
    };

    const onDragUpdate =(object) => {
        if (state.draggingPairPosition && object.destination) {
            if (state.draggingPairPosition > object.source.index) {
                if (object.destination.index>=state.draggingPairPosition-1) {
                    checkBehind(object.draggableId);
                } else {
                    checkUnder(object.draggableId);
                }
            } else {
                if (object.destination.index<=state.draggingPairPosition-1) {
                    checkUnder(object.draggableId);
                } else {
                    checkBehind(object.draggableId);
                }
            }
        }
    };

    const onDragEnd = (result) => {
        if (state.disableDrag) {
            errorMessage('Doručenie je pred vyzdvihnutím. Umiestnite stop mimo červených zásielok.', enqueueSnackbar, closeSnackbar)
            setState({...state,
                disableDrag: false,
                draggingId: null,
                draggingPairId: null,
                draggingPairPosition: null,
                draggingPairType: null,
            });
            return;
        }

        const {destination, source} = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const movedItem = filtered[source.index];
        const newItems = Array.from(filtered);
        newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, movedItem);
        client.writeQuery({
            query: GET_FILTER,
            data: {
                filter: {
                    ...dataFilter.filter,
                    orderSingle: 'custom',
                    __typename: 'Filter'
                }
            },
        });
        sendOrder(newItems);

        setState({...state,
            disableDrag: false,
            draggingId: null,
            draggingPairId: null,
            draggingPairPosition: null,
            draggingPairType: null,
        });
    };

    useEffect(() => {
        if (typeof data !== "undefined") {
            updateFilterCount(client, data.shipments, dataLocal.user, dataLocal.filter);
        }
    });

    return (
        <Fragment>
            {dataFilter.filter.shipingFilter !== 'myToDelivery' && <span>Zobrazenie stopy funguje iba pri filtri "Moje - nedorucene"</span>}
            <Grid
                container
                direction="row"
                alignItems="center"
                spacing={0}
                className={clsx(dataLocal.multiSelect && classes.grid)}
            >
                <Grid container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={1}>
                    {loading && <Loading/>}
                    <DragDropContext onDragEnd={onDragEnd}  onBeforeDragStart={onBeforeDragStart} onDragUpdate={onDragUpdate}>
                        <Droppable droppableId="list-point">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {filtered.map((object, index) => (
                                        <Draggable draggableId={object.id} key={object.id} index={index} >
                                            {(providedDraggable) => {
                                                let background = 'transparent';
                                                if (state.draggingPairId === object.id) {
                                                    background = red[100];
                                                } else if ( state.draggingId === object.id) {
                                                    background = '#fafafa';
                                                } else if (
                                                    (state.draggingPairType === Types.TO_PICKUP && index < state.draggingPairPosition) || (state.draggingPairType === Types.TO_DELIVERY && index >= state.draggingPairPosition)
                                                ) {
                                                    background = red[200];
                                                }


                                                const style = {
                                                    backgroundColor: background,
                                                    fontSize: 18,
                                                    ...providedDraggable.draggableProps.style,
                                                };

                                                return (
                                                    <div
                                                        {...providedDraggable.draggableProps}
                                                        ref={providedDraggable.innerRef}
                                                        style={style}
                                                    >
                                                        <ShippingPointTitle
                                                            key={object.id}
                                                            shipment={object.shipment}
                                                            type={object.type}
                                                            dragHandleProps={providedDraggable.dragHandleProps}
                                                            onAddressClick={handleAddressClick}
                                                        />
                                                    </div>
                                                )}
                                            }
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid>
            </Grid>

            <MapSelector
                open={isMapSelectorOpen}
                onClose={handleMapSelectorClose}
                address={selectedLocation.address}
                lat={selectedLocation.lat}
                lng={selectedLocation.lng}
            />

        </Fragment>
    );
};

export default forwardRef(ShipmentsSingle);
