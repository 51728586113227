import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {useMutation, makeVar, useReactiveVar} from '@apollo/client';
import {Loading} from '../../../components/index';
import RadioBlock from '../../../components/shipment/radioBlock';
import ConditionWrapper from '../../../components/shipment/conditionWrapper';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Line from "../../../components/shipment/line";
import {errorMessage, successMessage} from "../../../utils/messages";
import {format} from "date-fns";
import Label from "../../../components/shipment/label";
import Value from "../../../components/shipment/value";
import {
    getDefaultDeliveryCondition,
    have18,
} from '../delivery/utils';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import skLocale from "date-fns/locale/sk";
import Typography from "@material-ui/core/Typography";
import useStyles from "../delivery/styles";
import actions from "../delivery/actions";
import copy from "copy-to-clipboard";
import CheckboxBlock from "../../../components/shipment/checkboxBlock";

const SET_COD_REFERENCE = loader('../../../gql/mutations/setCODReferenceNumber.graphql');

const referenceVar = makeVar(null);

export default function DeliveryCheck({shipment, state, dispatch, isInDeliveryBox = false}) {
    const referenceNumber = useReactiveVar(referenceVar);
    const defaultAdditionalCondition = getDefaultDeliveryCondition(shipment);

    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [setCODReference, {loading: loadingCOD}] = useMutation(SET_COD_REFERENCE, {
        variables: {
            id: shipment.id
        },
        ignoreResults: true,
        onCompleted: (data) => {
            referenceVar({...referenceVar(), [data.setCODReferenceNumber.Zasielka_Kod]: data.setCODReferenceNumber.Dobierka.ReferenceNumber})
        },
        onError: (error) => errorMessage('Chyba pri ziskavaní COD variabilneho symbolu : ' + error, enqueueSnackbar, closeSnackbar),
    });

    const handleDate = (date) => {
        dispatch({
            type: actions.setDate,
            payload: date,
        });
    };


    const formatReferenceNumber = (referenceNumber) => {
        if (referenceNumber !== null) {
            const parts = referenceNumber.match(/.{1,2}/g);
            return parts.join(" ");
        }
        return '';
    }

    const handleAdditionalConditionChange = prop => value => {
        dispatch({
            type: actions.handleAdditionalConditions,
            field: prop,
            payload: value,
        });
    };

    // because lazyQuery on multiselect not update shipment after change cache we store it after mutation to state, if is cod reference loaded before use it instead dynamic loading it
    let referenceNumberLocal = shipment.Dobierka.ReferenceNumber !== null ? shipment.Dobierka.ReferenceNumber : (state.referenceNumber !== null ? state.referenceNumber : (referenceNumber !== null && typeof referenceNumber[shipment.Zasielka_Kod] !== "undefined" ? referenceNumber[shipment.Zasielka_Kod] : null));
    const referenceNumberFormatted = formatReferenceNumber(referenceNumberLocal || null);
    const handleCopyVSClick = () => {
        copy(referenceNumberLocal);
        successMessage('VS skopírovaný do schránky.', enqueueSnackbar, closeSnackbar);
    }
    const onlineValue = <Fragment>cez terminal
        {referenceNumberFormatted !== '' && <Fragment>, variabilny symbol:
        <span className={classes.referenceNumber}>{referenceNumberFormatted}</span>
        <Button
            onClick={handleCopyVSClick}
            variant="contained"
            startIcon={<FileCopyOutlinedIcon/>}
            className={classes.copyButton}
        >
            Kopírovať
        </Button>
        </Fragment>
        }
    </Fragment>;

   const handleCOD = (value) => {
        dispatch({
            type: actions.handleCOD,
            payload: value,
        });
        if (value === 'Online' && referenceNumberLocal === null) {
            setCODReference();
        }
    }

    return (
        <Fragment>
            {loadingCOD && <Loading/>}

            <Grid container spacing={0}>
                {shipment.NazovD && shipment.NazovD.length > 0 && (
                    <Grid item xs={12} sm={6}>
                        <Line>
                            {shipment.NazovD.length > 0 && (<span>firma: <b>{shipment.NazovD}</b></span>)}
                        </Line>
                        {shipment.DatumDoruc !== null && shipment.Dorucil && (
                            <Line>
                                <Label>doručené:</Label><Value>{format(shipment.DatumDoruc, 'd.M.y H:mm')} ({shipment.Dorucil.Nazov})</Value>
                            </Line>
                        )}
                    </Grid>
                )}

                {!defaultAdditionalCondition.OV && !state.values.disabled && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={skLocale}>
                        <Grid item xs={12}>
                            <ConditionWrapper valid={state.additionalConditions.OV}>
                                {!isInDeliveryBox && (
                                    <Fragment>
                                        <Typography variant="body1" gutterBottom>Zadajte dátum narodenia:</Typography>
                                        <KeyboardDatePicker
                                            clearable
                                            value={state.values.birthDate}
                                            onChange={date => handleDate(date)}
                                            disableFuture
                                            format="dd.MM.yyyy"
                                            views={["year", "month", "date"]}
                                            openTo="year"
                                            okLabel="Potvrdiť"
                                            cancelLabel="Zrušiť"
                                            inputProps={{
                                                inputMode: 'numeric',
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input,
                                                }
                                            }}
                                        />
                                        {state.values.birthDate && !have18(state.values.birthDate) && (
                                            <Typography variant="body1" gutterBottom>Menej ako 18 rokov, nemožno
                                                doručiť</Typography>
                                        )}
                                    </Fragment>
                                )}

                                {isInDeliveryBox && (
                                    <Fragment>
                                    Zásielku s overením veku nemožno doručiť do baliko-boxu.
                                    </Fragment>
                                )}
                            </ConditionWrapper>
                        </Grid>
                    </MuiPickersUtilsProvider>
                )}

                {!defaultAdditionalCondition.COD && !state.values.disabled && (
                    <Grid item xs={12}>
                        <ConditionWrapper valid={state.additionalConditions.COD}>
                            {loadingCOD && (<Loading/>)}

                            {isInDeliveryBox && (
                                <Fragment>
                                    Zásielku s dobierkou nemožno doručiť do baliko-boxu.
                                </Fragment>
                            )}

                            {!isInDeliveryBox && (
                                <RadioBlock
                                    description={
                                        <>
                                            Vybral som dobierku <b><span style={{ fontSize: '1.2em' }}>{shipment.Dobierka.Amount}{shipment.Dobierka.Currency ? shipment.Dobierka.Currency : ''}</span></b>
                                        </>
                                    }
                                    values={{
                                        'Cash': 'v hotovosti.',
                                        'Online': onlineValue,
                                    }}
                                    value={state.values.CODPaymentType}
                                    name="COD"
                                    onChange={handleCOD}
                                />
                            )}
                        </ConditionWrapper>
                    </Grid>
                )}

                {!defaultAdditionalCondition.packageCount && !state.values.disabled && (
                    <Grid item xs={12}>
                        <ConditionWrapper valid={state.additionalConditions.packageCount}>
                            <CheckboxBlock
                                label={"Doručil som všetky balíky v počte " + shipment.PocetKusov + " ks."}
                                name="packageCount" onChange={handleAdditionalConditionChange('packageCount')}/>
                        </ConditionWrapper>
                    </Grid>
                )}

            </Grid>
        </Fragment>
    );
}
