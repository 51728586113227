import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {endOfDay, format, parse, startOfDay, subDays} from 'date-fns';
import PhoneInTalk from '@material-ui/icons/PhoneInTalk';
import MapIcon from '@material-ui/icons/Map';
import SmsIcon from '@material-ui/icons/Sms';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import MyPaper from '../../components/shipment/paper';
import Value from '../../components/shipment/value';
import Label from '../../components/shipment/label';
import Line from '../../components/shipment/line';
import Header from "../../components/shipment/header";
import CountDown from "../../components/countdown";
import {isNotAssign, isSystemCourier} from "../../utils";
import {loader} from "graphql.macro";
import {useMutation} from "@apollo/client";
import {Loading} from "../../components";
import {errorMessage, successMessage} from "../../utils/messages";
import {useSnackbar} from "notistack";
import {navigate, useMatch} from "@gatsbyjs/reach-router";
import SmsHref from "../../components/shipment/infoTab/smsHref";
import SmsSelect from "../../components/shipment/infoTab/smsSelect";
import SmsTime from "../../components/shipment/smsTime";
import SmsTimeWrapper from "../../components/shipment/smsTimeWrapper";
import {
    createAddressDataObjectForDelivery, createAddressDataObjectForPickup,
} from "../../components/shippingTitle/utils";
import {getShipmentCodeForReturnSubShipmentFromBox} from "./utils";
import MapSelector from "../../components/mapSelector/mapSelector";

const ASSIGN_MUTATION = loader('../../gql/mutations/assignShipment.graphql');
const GET_SHIPMENTS = loader('../../gql/query/shipments.graphql');

const useStyles = makeStyles(theme => ({
    icons: {
        fontSize: 25,
    },
    iconHref: {
        color: theme.colors.href,
        display: 'inline-block',
        position: 'relative',
        zIndex: '1',
        padding: '1em 1em',
        margin: '-1em 0',
    },
    cashOnDeliveryBlock: {
        backgroundColor: theme.colors.redDomca,
        color: theme.colors.white,
    },
    street: {
        display: 'inline-block',
        textTransform: 'lowercase',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
    city: {
        display: 'inline-block',
        textTransform: 'initial',
    },
    yellowBg: {
        backgroundColor: '#fffc49',
    }
}));

export default function InfoTab({shipment, dataLocal}) {
    const classes = useStyles();
    const isScannerUrl = useMatch('/scanner/*');
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const [isMapSelectorOpen, setIsMapSelectorOpen] = React.useState(false);
    const [selectedLocation, setSelectedLocation] = React.useState({ address: null, lat: null, lng: null });

    let from = startOfDay(subDays(new Date(), process.env.REACT_APP_HISTORY_DATE));
    let to = endOfDay(new Date());

    const [sendAssign, {loading}] = useMutation(ASSIGN_MUTATION, {
        onError: (error) => errorMessage('Chyba pri odosielaní: '+error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Uspešne priradené.', enqueueSnackbar, closeSnackbar);
            if (isScannerUrl) {
                navigate('/scanner');
            }
        },
        //refetchQueries: () => ['shipmentList'],
        refetchQueries: () => [{
            query: GET_SHIPMENTS,
            variables: {
                fromDate: format(from, 'yyyy-MM-dd'),
                toDate: format(to, 'yyyy-MM-dd'),
            },
        }],
    });

    const handleAssign = () => {
        sendAssign(
            {
                variables: {zasielkaKod: shipment.Zasielka_Kod}
            }
        );
    };

    const handleAddressClick = (addressData) => {
        setSelectedLocation(addressData);
        setIsMapSelectorOpen(true);
    };

    const handleMapSelectorClose = () => {
        setIsMapSelectorOpen(false);
        setSelectedLocation({ address: null, lat: null, lng: null });
    };

    const isNotAssigned = isNotAssign(shipment);

    return (
        <Fragment>
            {(loading) && <Loading/>}
            {(isNotAssigned || isSystemCourier(shipment) || (getShipmentCodeForReturnSubShipmentFromBox(shipment.customerIdentification) && shipment.Priradene?.Kurier_Kod !== dataLocal?.user?.kod)) && ((dataLocal && dataLocal.user.isAdmin) || isScannerUrl || getShipmentCodeForReturnSubShipmentFromBox(shipment.customerIdentification)) && (
                <Line>
                    <Button
                        fullWidth={true}
                        variant="contained"
                        onClick={handleAssign}
                    >
                        Priradiť zásielku mne {shipment.ZStav_popis.ZStav_Popis_Kod === 'HVR' && shipment.Priradene === null && ( <Fragment> - Prekladaná na {shipment.LastExchange.Street} {shipment.LastExchange.StreetNumber}</Fragment>)}
                    </Button>
                </Line>
            )}

            <MyPaper className={classes.block}>
                <Line>
                    <Grid container spacing={2}>
                        {shipment.Hmotnost && (
                            <Grid item xs={6}><Label>hmotnosť:</Label><Value>{shipment.Hmotnost} kg</Value></Grid>
                        )}
                        {shipment.PocetKusov && (
                            <Grid item xs={6}><Label>ks:</Label><Value>{shipment.PocetKusov}</Value></Grid>
                        )}
                    </Grid>
                </Line>
                <Line>
                    <Label>typ:</Label><Value>{shipment.ShippingTimeGroup}/{shipment.ShippingTimeName}</Value>
                </Line>
                {shipment.customerIdentification !== null && shipment.customerIdentification !== '' && (
                    <Line>
                        <Label>kód objednávateľa:</Label><Value>{shipment.customerIdentification}</Value>
                    </Line>
                )}
                <Line>
                    <Label>objednané:</Label><Value>{format(shipment.DatumObjed, 'd.M.y H:mm')}</Value>
                </Line>
                {shipment.DatumPrevz !== null && shipment.Prevzal &&  (
                    <Line>
                        <Label>vyzdvihnuté:</Label><Value>{format(shipment.DatumPrevz, 'd.M.y H:mm')} ({shipment.Prevzal.Nazov})</Value>
                    </Line>
                )}
                {shipment.ETAPickUp !== null && !shipment.Prevzal &&  (
                    <Line>
                        <Label>predpokladaný čas vyzdvihnutia:</Label><Value>{format(shipment.ETAPickUp, 'd.M.y H:mm')}</Value>
                    </Line>
                )}
                {shipment.DatumDoruc !== null && shipment.Dorucil && (
                    <Line>
                        <Label>doručené:</Label><Value>{format(shipment.DatumDoruc, 'd.M.y H:mm')} ({shipment.Dorucil.Nazov})</Value>
                    </Line>
                )}
                {shipment.ETADelivery !== null && !shipment.Dorucil && (
                    <Line>
                        <Label>predpokladaný čas doručenia:</Label><Value>{format(shipment.ETADelivery, 'd.M.y H:mm')}</Value>
                    </Line>
                )}
                <SmsTimeWrapper shipment={shipment}>
                    <Line>
                        <Label>čas doručenia odoslaný v sms:</Label><Value><SmsTime shipment={shipment} addSpaces={true}/></Value>
                    </Line>
                </SmsTimeWrapper>
                <Line>
                    <Label>platca:</Label><Value>{shipment.PayerCompany.Nazov}</Value>
                </Line>
                {shipment.DestinationSender != null && (
                    <Line>
                        <Label>Destinácia príjmateľa:</Label><Value>{shipment.DestinationSender.Nazov}</Value>
                    </Line>
                )}
                {shipment.DestinationConsignee != null && (
                    <Line>
                        <Label>Destinácia odosielateľa:</Label><Value>{shipment.DestinationConsignee.Nazov}</Value>
                    </Line>
                )}
                {shipment.PoznamkaKlient != null && shipment.PoznamkaKlient.length > 0 && (
                    <Line>
                        <Label>pozn.klient:</Label><Value>{shipment.PoznamkaKlient}</Value>
                    </Line>
                )}
                {shipment.PoznamkaKlient2 != null && shipment.PoznamkaKlient2.length > 0 && (
                    <Line>
                        <Label>pozn.klient2:</Label><Value>{shipment.PoznamkaKlient2}</Value>
                    </Line>
                )}
                {shipment.customerZone != null && shipment.customerZone.length > 0 && (
                    <Line>
                        <Label>zóna klient:</Label><Value>{shipment.customerZone}</Value>
                    </Line>
                )}
                {shipment.PoznamkaIntern != null && shipment.PoznamkaIntern.length > 0 && (
                    <Line>
                        <Label>pozn.interna:</Label><Value>{shipment.PoznamkaIntern}</Value>
                    </Line>
                )}
                {shipment.Insurance && (
                    <Line>
                        <Label>poistenie:</Label><Value>{shipment.Insurance}</Value>
                    </Line>
                )}
                {shipment.Attributes != null && shipment.Attributes.length > 0 && (
                    <Line>
                        <Label>rozmery:</Label>
                        {shipment.Attributes.map((attribute, index) =>
                            <Fragment key={index}><Value>{attribute}</Value></Fragment>
                        ).reduce((prev, curr) => [prev, ' / ', curr])}
                    </Line>
                )}
                {shipment.DobaCakania !== null && shipment.DobaCakania !== "00:00:00" &&(
                    <Line>
                        <Label>čakanie pri preberaní:</Label><Value>{shipment.DobaCakania}</Value>
                    </Line>
                )}
                {shipment.DobaCakania2 !== null && shipment.DobaCakania2 !== "00:00:00" &&(
                    <Line>
                        <Label>čakanie pri doručovaní:</Label><Value>{shipment.DobaCakania2}</Value>
                    </Line>
                )}
                {shipment.DeliveryTime &&(
                    <Line>
                        <Label>limit na doručenie:</Label><Value><CountDown date={shipment.DeliveryTime}/></Value>
                    </Line>
                )}
            </MyPaper>


            {shipment.Dobierka != null && shipment.Dobierka && shipment.Dobierka.Amount && (
                <MyPaper className={clsx(classes.cashOnDeliveryBlock, classes.block)}>
                    <Line>
                        <Label>DOBIERKA:</Label><Value>{shipment.Dobierka.Amount}{shipment.Dobierka.Currency && (shipment.Dobierka.Currency)}</Value>
                    </Line>
                    {shipment.Dobierka.PaymentType !== null && (
                        <Line>
                            <Label>Spôsob platby:</Label><Value>{shipment.Dobierka.PaymentType}</Value>  {shipment.Dobierka.ReferenceNumber !== null && (<Fragment><Label>VS:</Label><Value>{shipment.Dobierka.ReferenceNumber}</Value></Fragment>)}
                        </Line>
                    )}
                </MyPaper>
            )}


            {shipment.Typ_Platby === 2 && (
                <MyPaper className={clsx(classes.cashOnDeliveryBlock, classes.block)}>
                    <Line>
                        <Label>Typ platby:</Label><Value>v hotovosti</Value>
                    </Line>
                </MyPaper>
            )}


            <MyPaper className={classes.block}>
                <Header>Odosielateľ</Header>
                {(shipment.NazovO.length > 0 || shipment.MenoO.length > 0 || shipment.PriezviskoO.length > 0) && (
                    <Line>
                        {shipment.NazovO.length > 0 && (<b>{shipment.NazovO}</b>)} / {shipment.MenoO.length > 0 && shipment.MenoO} {shipment.PriezviskoO.length > 0 && shipment.PriezviskoO}
                    </Line>
                )}
                {(shipment.UlicaO.length > 0 || shipment.ObecO.length > 0 ) && (
                    <Line>
                        {shipment.UlicaO && shipment.UlicaO.length > 0 && (
                            <div className={classes.street}>{shipment.UlicaO} {shipment.CisloO.length > 0 && shipment.CisloO}, <div className={classes.city}>{shipment.ObecO.length > 0 && shipment.ObecO}</div></div>
                        )}
                        {shipment.UlicaO.length > 0 && shipment.ObecO.length > 0 && shipment.CisloO.length > 0  && (
                            <a
                                onClick={(event) => {
                                    event.preventDefault();
                                    createAddressDataObjectForPickup(shipment)
                                }}
                                className={classes.iconHref}
                            >
                                <MapIcon className={classes.icons}/>
                            </a>
                        )}
                        {shipment.AreaSender && (
                            <Fragment>(zona: {shipment.AreaSender.Label})</Fragment>
                        )}
                    </Line>
                )}
                {shipment.TelefonO.length > 0 && (
                    <Line>
                        {shipment.TelefonO}
                        <a href={"tel:"+shipment.TelefonO} className={classes.iconHref}><PhoneInTalk className={classes.icons}/></a>
                        <SmsHref
                            to={shipment.TelefonO}
                            isDelivery={false}
                            className={classes.iconHref}
                            shipment={shipment}
                        >
                            <SmsIcon className={classes.icons}/>
                        </SmsHref>
                        <SmsSelect shipment={shipment} isPickup={true}/>
                    </Line>
                )}
            </MyPaper>


            {(shipment.InternPickUp &&
                <MyPaper className={clsx(classes.block,classes.yellowBg)}>
                    <Header>Aktuálna lokácia zásielky</Header>
                    <Line>
                        {shipment.InternPickUpStreet && shipment.InternPickUpStreet.length > 0 && (
                            <div className={classes.street}>{shipment.InternPickUpStreet} {shipment.InternPickUpStreetNumber.length > 0 && shipment.InternPickUpStreetNumber}, <div className={classes.city}>{shipment.InternPickUpCity.length > 0 && shipment.InternPickUpCity}</div></div>
                        )}
                        {shipment.InternPickUpStreet.length > 0 && shipment.InternPickUpCity.length > 0 && shipment.InternPickUpStreetNumber.length > 0  && (
                            <a
                                className={classes.iconHref}
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleAddressClick({
                                        address: shipment.InternPickUpStreet + ' ' + shipment.InternPickUpStreetNumber + ',' + shipment.InternPickUpCity,
                                    })
                                }}
                            >
                                <MapIcon className={classes.icons}/>
                            </a>
                        )}
                    </Line>
                </MyPaper>
            )}


            <MyPaper className={classes.block}>
                <Header>Príjemca</Header>
                {(shipment.NazovD.length > 0 || shipment.MenoD.length > 0 || shipment.PriezviskoD.length > 0) && (
                    <Line>
                        {shipment?.NazovD.length > 0 && (<b>{shipment.NazovD}</b>)} / {shipment.MenoD.length > 0 && shipment.MenoD} {shipment.PriezviskoD.length > 0 && shipment.PriezviskoD}
                    </Line>
                )}
                {((shipment.UlicaD && shipment.UlicaD.length > 0) || shipment.ObecD.length ) && (
                    <Line>
                        {shipment.UlicaD && shipment.UlicaD.length > 0 && (
                            <div className={classes.street}>{shipment.UlicaD} {shipment.CisloD.length > 0 && shipment.CisloD}, <div className={classes.city}>{shipment.ObecD.length > 0 && shipment.ObecD}</div></div>
                        )}
                        {shipment.UlicaD.length > 0 && shipment.ObecD.length > 0 && shipment.CisloD.length > 0  && (
                            <a
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleAddressClick(createAddressDataObjectForDelivery(shipment))
                                }}
                                className={classes.iconHref}
                            >
                                <MapIcon className={classes.icons}/>
                            </a>
                        )}
                        {shipment.AreaDelivery && (
                            <Fragment>(zona: {shipment.AreaDelivery.Label})</Fragment>
                        )}
                    </Line>
                )}
                {shipment.TelefonD && shipment.TelefonD.length > 0 && (
                    <Line>
                        {shipment.TelefonD}
                        <a href={"tel:"+shipment.TelefonD} className={classes.iconHref}><PhoneInTalk className={classes.icons}/></a>
                        <SmsHref
                            to={shipment.TelefonD}
                            isDelivery={true}
                            className={classes.iconHref}
                            shipment={shipment}
                        >
                            <SmsIcon className={classes.icons}/>
                        </SmsHref>
                        <SmsSelect shipment={shipment}/>
                    </Line>
                )}
            </MyPaper>



            {shipment.Services != null && shipment.Services.length > 0 && (
                <MyPaper className={classes.block}>
                    <Header>Doplnkové služby</Header>
                    {shipment.Services.map((service) =>
                        <Line key={service.Nazov}>
                            {service.Nazov}
                        </Line>
                    )}
                </MyPaper>
            )}

            <MapSelector
                open={isMapSelectorOpen}
                onClose={handleMapSelectorClose}
                address={selectedLocation.address}
                lat={selectedLocation.lat}
                lng={selectedLocation.lng}
            />
        </Fragment>
    );
}
