import {removeAccents, wasAssign, wasPickUp} from "../../utils";
import Types from "../../datasource/shipmentPointsGraphQL";
import {checkSearch, isMine, wasDelivered, updateSecondFilterCount} from "../../pages/shipmentsHelper";
import {secondFilter} from "./index";

const getOrder = (shipment, type) => {
    let i;
    for (i = 0; i < shipment.Orders.length; i++) {
        if (type === shipment.Orders[i].Adresa_Typ) {
            return shipment.Orders[i].Poradie;
        }
    }

    return undefined;
};

const createShipmentPoints = shipments => {
    let shipmentPoint = [];
    let object;
    if (shipments) {
        for (let i = 0; i < shipments.length; i++) {
            if (wasPickUp(shipments[i])) {
                object = {
                    type: Types.TO_DELIVERY,
                    id: shipments[i].Id_Zasielka + String(Types.TO_DELIVERY),
                    shipment: shipments[i],
                    order: getOrder(shipments[i], Types.TO_DELIVERY),
                    orderRoute: shipments[i]?.ShipmentRoute?.find(shipmentRoute => shipmentRoute.pointType === "Delivery")?.order,
                };
                shipmentPoint.push(object);
            } else if (wasAssign(shipments[i])) {
                object = {
                    type: Types.TO_PICKUP,
                    id: shipments[i].Id_Zasielka + String(Types.TO_PICKUP),
                    shipment: shipments[i],
                    order: getOrder(shipments[i], Types.TO_PICKUP),
                    orderRoute: shipments[i]?.ShipmentRoute?.find(shipmentRoute => shipmentRoute.pointType === "Pickup")?.order,
                };
                shipmentPoint.push(object);

                object = {
                    type: Types.TO_DELIVERY,
                    id: shipments[i].Id_Zasielka + String(Types.TO_DELIVERY),
                    shipment: shipments[i],
                    order: getOrder(shipments[i], Types.TO_DELIVERY),
                    orderRoute: shipments[i]?.ShipmentRoute?.find(shipmentRoute => shipmentRoute.pointType === "Delivery")?.order,
                };
                shipmentPoint.push(object);
            }
        }
    }

    return shipmentPoint;
};

const createShipmentPointsFromRoute = shipments => {
    let shipmentPoint = [];
    let object;
    if (shipments) {
        for (let i = 0; i < shipments.length; i++) {
            if (!shipments[i].ShipmentRoute) {
                continue;
            }

            for (const shipmentRoute of shipments[i].ShipmentRoute) {
                if (shipmentRoute.pointType === "Delivery") {
                    object = {
                        type: Types.TO_DELIVERY,
                        id: shipments[i].Id_Zasielka + String(Types.TO_DELIVERY),
                        shipment: shipments[i],
                        shipmentRoute: shipmentRoute,
                        order: shipmentRoute.order,
                    };
                } else if (shipmentRoute.pointType === "Pickup") {
                    object = {
                        type: Types.TO_PICKUP,
                        id: shipments[i].Id_Zasielka + String(Types.TO_PICKUP),
                        shipment: shipments[i],
                        shipmentRoute: shipmentRoute,
                        order: shipmentRoute.order,
                    };
                } else {
                    throw Error('Invalid shipment point type ' + shipmentRoute.pointType);
                }

                shipmentPoint.push(object);
            }
        }
    }

    return shipmentPoint;
};

const filterShipments = (items, filter, user) => {
    let isSearch = filter.search !== '';
    let isAllFilter = filter.shipingFilter === 'all';
    let isMyToDeliveryFilter = filter.shipingFilter === 'myToDelivery';
    const search = removeAccents(filter.search.toLowerCase());

    const shipmentsFiltred = items.filter(({shipment}) => {
        const wasDeliveredShipment = wasDelivered(shipment, user.kod);

        if (isAllFilter && !wasDeliveredShipment) {
            return isSearch ? checkSearch(shipment, search) : true;
        } else if (isMyToDeliveryFilter && isMine(shipment, user.kod) && !wasDeliveredShipment) {
            return isSearch ? checkSearch(shipment, search) : true;
        }

        return false;
    });

    return shipmentsFiltred;
};

const filterPointsSecond = (items, filterSecond) => items?.filter(item => secondFilter(item.shipment, filterSecond));

const updateSecondFilterCountPoint = (client, items, user) => {
    updateSecondFilterCount(client, items.map(item => item.shipment), user);
}

export {
    createShipmentPoints,
    filterShipments,
    filterPointsSecond,
    updateSecondFilterCountPoint,
    createShipmentPointsFromRoute,
}
