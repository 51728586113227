import React from 'react';
import {Link} from "@gatsbyjs/reach-router";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarcode, faCamera} from "@fortawesome/free-solid-svg-icons";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles(theme => ({
    button: {
        fontSize: 20,
    },
    gridItem: {
        textAlign: 'center',
        fontSize: 70,
    }
}));

const ScannerSelect = () => {
    const classes = useStyles();
    return (
        <Grid container item xs={12} spacing={0} justifyContent="center" alignItems="center">
            <Grid item xs={12} className={classes.gridItem}>
                <Link to="/scanner">
                    <Button
                        className={classes.button}
                        variant="contained"
                        size="large"

                    >
                        použiť kameru&nbsp;
                        <FontAwesomeIcon style={{fontSize: 48}} icon={faCamera} />
                    </Button>
                </Link>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
                <Link to="/scanner-input">
                    <Button
                        className={classes.button}
                        variant="contained"
                        size="large"

                    >
                        použiť ručný skener&nbsp;
                        <FontAwesomeIcon style={{fontSize: 48}} icon={faBarcode} />
                    </Button>
                </Link>
            </Grid>
            <Link to="/">
                <Fab color="primary" style={{position: 'absolute', top: '4%', right: '6%', zIndex: 100}}
                     aria-label="scan">
                    <CloseIcon/>
                </Fab>
            </Link>
        </Grid>
    );
};

export default ScannerSelect;
