import { useEffect, useRef } from "react";
import "./dbr";
import { CameraEnhancer, CameraView } from "dynamsoft-camera-enhancer";
import { CaptureVisionRouter } from "dynamsoft-capture-vision-router";
import { MultiFrameResultCrossFilter } from "dynamsoft-utility";
import { EnumBarcodeFormat } from "dynamsoft-barcode-reader";

const componentDestroyedErrorMsg = "VideoCapture Component Destroyed";

function BarcodeScannerComponent({onError, onScan}) {
    const cameraViewContainer = useRef(null);

    useEffect(() => {
        let resolveInit;
        const pInit = new Promise((r) => {
            resolveInit = r;
        });
        let isDestroyed = false;

        let cvRouter;
        let cameraEnhancer;

        (async () => {
            try {
                // Create a `CameraEnhancer` instance for camera control and a `CameraView` instance for UI control.
                const cameraView = await CameraView.createInstance();
                if (isDestroyed) {
                    throw Error(componentDestroyedErrorMsg + '1');
                } // Check if component is destroyed after every async
                cameraEnhancer = await CameraEnhancer.createInstance(cameraView);
                if (isDestroyed) {
                    throw Error(componentDestroyedErrorMsg + '2');
                }

                // Get default UI and append it to DOM.
                if (cameraViewContainer.current) { // Check if cameraViewContainer.current is truthy (not null, undefined, 0, "", NaN, false)
                    cameraViewContainer.current.append(cameraView.getUIElement());
                } else {
                    console.warn("cameraViewContainer.current is null or undefined, cannot append.");
                    // Or handle the case where it's null/undefined in a different way
                }

                // Create a `CaptureVisionRouter` instance and set `CameraEnhancer` instance as its image source.
                cvRouter = await CaptureVisionRouter.createInstance();
                if (isDestroyed) {
                    throw Error(componentDestroyedErrorMsg + '3');
                }

                cvRouter.setInput(cameraEnhancer);

                // Obtain current runtime settings of `router` instance. Here we use `ReadSingleBarcode` as an example. You can change it to your own template name or the name of other preset template.
                let settings = await cvRouter.getSimplifiedSettings("ReadSingleBarcode");
                // Specify the barcode formats by enumeration values.
                // Use "|" to enable multiple barcode formats at one time.
                settings.barcodeSettings.barcodeFormatIds = EnumBarcodeFormat.BF_ONED | EnumBarcodeFormat.BF_QR_CODE;
                // Update the settings to a specific template.
                await cvRouter.updateSettings("ReadSingleBarcode", settings);

                // Define a callback for results.
                cvRouter.addResultReceiver({
                    onDecodedBarcodesReceived: (result) => {
                        if (!result.barcodeResultItems.length)
                            return;

                        onScan(result.barcodeResultItems[0].text);
                    },
                });

                // Filter out unchecked and duplicate results.
                const filter = new MultiFrameResultCrossFilter();
                // Filter out unchecked barcodes.
                filter.enableResultCrossVerification("barcode", true);
                // Filter out duplicate barcodes within 3 seconds.
                filter.enableResultDeduplication("barcode", true);
                await cvRouter.addResultFilter(filter);
                if (isDestroyed) {
                    throw Error(componentDestroyedErrorMsg + '4');
                }

                // Open camera and start scanning single barcode.
                await cameraEnhancer.open();
                cameraView.setScanLaserVisible(true);
                if (isDestroyed) {
                    throw Error(componentDestroyedErrorMsg + '5');
                }
                await cvRouter.startCapturing("ReadSingleBarcode");
                if (isDestroyed) {
                    throw Error(componentDestroyedErrorMsg + '6');
                }
            } catch (ex) {
                let errMsg = ex.message || ex;
                console.error(errMsg);
                onError(ex);
            }
            // Resolve pInit promise once initialization is complete.
            resolveInit();
        })();

        // componentWillUnmount. dispose cvRouter when it's no longer needed
        return () => {
            isDestroyed = true;
            // Wait for the pInit to complete before disposing resources.
            pInit.then(() => {
                cvRouter?.dispose();
                cameraEnhancer?.dispose();
            }).catch((_) => { })
        };
    }, []);

    return (
        <div ref={cameraViewContainer} style={{width: "100%", height: "70vh"}}></div>
    );
}

export default BarcodeScannerComponent;