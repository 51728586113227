import React, {useState, Fragment} from 'react';
import {Link, navigate} from "@gatsbyjs/reach-router";
import Fab from "@material-ui/core/Fab";
import CloseIcon from '@material-ui/icons/Close';
import {useLazyQuery, useQuery} from "@apollo/client";
import {wasAssign, wasDelivery, wasPickUp} from "../utils";
import {loader} from "graphql.macro";
import {errorMessage} from "../utils/messages";
import {okSound, duplicitySound, errorSound} from "../utils/sounds/";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import {isMine} from "./shipmentsHelper";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import MultiSelectPanel from "../components/shipments/MultiSelectPanel";
import {persistActualUrl} from "../utils/history/lastUrlPersist";
import {TextField} from "@material-ui/core";
import {Loading} from "../components";

const GET_SHIPMENT_SCANNER = loader('../gql/query/shipmentScannerNote.graphql');
const GET_FILTER = loader('../gql/query/client/filter.graphql');

const useStyles = makeStyles(theme => ({
    code: {
        textAlign: 'center',
        fontSize: '20px',
        marginBottom: 20,
        marginLeft: 15,
    },
    radio: {
        margin: '5px 20px',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    spaceForMultiPanel: {
        marginBottom: 153,
    },
    wrapper: {
        margin: "2vmin auto",
        marginTop: -80,
        textAlign: "center",
        fontSize: "medium",
        height: "54vh",
        width: "100vw",
        [theme.breakpoints.up('sm')]: {
            width: `calc(100vw - ${theme.custom.main.drawerWidth}px)`,
        },
    },
}));

const ScannerInput = () => {
    const classes = useStyles();
    const [code, setCode] = useState(null);
    const [isMulti, setMulti] = useState(false);
    const [multiselectShipments, setMultiselectShipments] = React.useState([]);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {data: dataLocal} = useQuery(GET_FILTER);
    const [getShipment, {loading}] = useLazyQuery(GET_SHIPMENT_SCANNER, {
        fetchPolicy: 'cache-and-network', /// TODO AFTER FIXED https://github.com/apollographql/react-apollo/issues/2177 can be set to cache-first
        onCompleted: function (data) {
            if (typeof data !== "undefined") {
                if (data && data.shipmentNoteSearch && data.shipmentNoteSearch.length === 1) {
                    const shipment = data.shipmentNoteSearch[0];
                    const wasPickedUp = wasPickUp(shipment);
                    const wasDelivered = wasDelivery(shipment);
                    const wasAssigned = wasAssign(shipment);
                    const isMineVar = isMine(shipment, data.user.kod);

                    let url;
                    if (wasDelivered) {
                        url = '/scanner/' + shipment.Zasielka_Kod;
                    } else if (wasPickedUp && isMineVar) {
                        url = '/scanner/' + shipment.Zasielka_Kod + '/delivery';
                    } else if (wasAssigned && isMineVar) {
                        url = '/scanner/' + shipment.Zasielka_Kod + '/pickup';
                    } else {
                        url = '/scanner/' + shipment.Zasielka_Kod;
                    }
                    setCode(null);
                    navigate(url);
                } else if (data && data.shipmentNoteSearch && data.shipmentNoteSearch.length >= 1) {
                    setCode(null);
                    navigate('scanner/search/note/' + code + '/');
                } else {
                    errorMessage('Zasielka: ' + code + ' neexistuje alebo k nej nemate opravnenie.', enqueueSnackbar, closeSnackbar);
                }
            }
        }
    });

    const handleDeleteChip = (chipToDelete) => () => {
        handleDeleteFromMultiselect(chipToDelete);
    };

    const handleDeleteFromMultiselect = (shipmentId) => {
        if (shipmentId instanceof RegExp) {
            setMultiselectShipments((chips) => chips.filter((chip) => !shipmentId.test(chip)));
        } else {
            const toDelete = String(shipmentId);
            setMultiselectShipments((chips) => chips.filter((chip) => chip !== toDelete));
        }
    };

    const normalizeITFCode = code => {
        return code.replace(/\b0+/g, ''); // remove leading / left zeros
    };

    const addToMulti = (id) => {
        if (!multiselectShipments.includes(id)) {
            okSound();
            setMultiselectShipments([...multiselectShipments,id]);
        } else {
            duplicitySound();
        }
    };

    const modifyCode = (code) => {
        //mall external code, need remove last 3 counter chars, start with 11
        if (code.length === 13 && code.charAt(0) === '1' && code.charAt(1) === '1') {
            code = code.substr(0, 10);
        }

        return code;
    }

    const handleScan = data => {
        if (data) {
            data = String(data);
            if (data !== code) {
                setCode(data);

                data = modifyCode(data);
                if (isMulti) {
                    addToMulti(data);
                } else {
                    okSound();
                    processGo4Code(data);
                }
            } else {
                duplicitySound();
            }
        }
    };

    const processGo4Code = newCode => {
        const normalizedCode = normalizeITFCode(newCode);
        //if (validateCode(normalizedCode)) {
            getShipment({
                variables: {
                    note: normalizedCode,
                    fromDate: dataLocal.fromDate,
                    toDate: dataLocal.toDate,
                }
            });
        //}
    };

    const handleSwitchMulti = () => {
        if (isMulti) {
            setMulti(false);
        } else {
            handleResetMultiSelect();
            setMulti(true);
        }
    }

    const handleResetMultiSelect = () => {
        setMultiselectShipments([]);
    }

    const handleError = err => {
        errorMessage('Chyba: ' + err, enqueueSnackbar, closeSnackbar);
        errorSound();
    };

    const handleFocus = (event) => event.target.select();

    persistActualUrl();

    return (
        <div className={classes.wrapper}>
            {loading && <Loading/>}
            <TextField
                label="Zadajte kod zasielky a enter"
                variant="outlined"
                autoFocus
                style={{
                    'marginTop': '90px',
                }}
                onFocus={handleFocus}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        handleScan(e.target.value);
                        e.target.select();
                    }
                }}
            />
            <div className={classes.code}>načítaný kód: {code}</div>

            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Single mod</Grid>
                <Grid item>
                    <Switch
                        checked={isMulti}
                        onChange={handleSwitchMulti}
                        value="single"/>
                </Grid>
                <Grid item>Multi mod</Grid>
            </Grid>

            {isMulti &&
                <Fragment>
                    <Paper component="ul" className={classes.root}>
                        {multiselectShipments.map((data, key) => {
                            return (
                                <li key={key}>
                                    <Chip
                                        label={data}
                                        onDelete={handleDeleteChip(data)}
                                        className={classes.chip}
                                    />
                                </li>
                            );
                        })}
                        <div className={classes.spaceForMultiPanel}></div>
                    </Paper>
                    <MultiSelectPanel selectedShipments={multiselectShipments}
                                      removeFromMultiselect={handleDeleteFromMultiselect}
                                      allowAssign={true}
                    />
                </Fragment>
            }

            <Link to="/">
                <Fab color="primary" style={{position: 'absolute', top: '4%', right: '6%', zIndex: 100}}
                     aria-label="scan">
                    <CloseIcon/>
                </Fab>
            </Link>
        </div>
    );
};

export default ScannerInput;
