import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GoogleMapsIcon from '@material-ui/icons/Map';
import WazeIcon from '@material-ui/icons/DirectionsCar';
import MapyCzIcon from '@material-ui/icons/Public';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useSnackbar} from "notistack";
import {errorMessage} from "../../utils/messages";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: { // For small screens and below (phones)
            alignItems: 'flex-end', // Align modal to the bottom
            marginBottom: theme.spacing(4), // Add some margin from the bottom
        },
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius, // Use theme's border radius
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 4), // Increase padding for better spacing
        textAlign: 'center',
        width: '90%', // Limit width on smaller screens
        maxWidth: 400, // Maximum width
        [theme.breakpoints.down('sm')]: {
            width: '100%', // Full width on small screens
            maxWidth: 'none',
            borderRadius: '10px 10px 0 0', // Round top corners on mobile
        },
    },
    iconButton: {
        margin: theme.spacing(1),
        display: 'flex', // Enable flex layout for icon and text
        flexDirection: 'column', // Stack icon and text vertically
        alignItems: 'center', // Center items horizontally
    },
    mapIcon: {
        fontSize: '3rem', // Larger icons
        color: theme.palette.primary.main, // Example primary color, adjust as needed
        marginBottom: theme.spacing(0.5), // Spacing between icon and text
    },
    providerName: {
        fontSize: '0.8rem', // Smaller text for provider name
        color: theme.palette.text.secondary, // Use secondary text color
    },
}));

export default function MapSelector({ open, onClose, address, lat, lng }) {
    const classes = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if it's mobile

    // Check if we have either address or lat/lng to display the modal
    if (!address && (!lat || !lng)) {
        return null; // Don't render if no address and no coordinates
    }

    const handleMapAppClick = (mapApp) => {

        let mapUrl = '';
        const encodedAddress = address ? encodeURIComponent(address) : '';
        const latitude = lat ? parseFloat(lat) : null;
        const longitude = lng ? parseFloat(lng) : null;

        switch (mapApp) {
            case 'google':
                if (latitude && longitude) {
                    mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
                } else if (address) {
                    mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
                }
                break;
            case 'waze':
                if (latitude && longitude) {
                    mapUrl = `https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;
                } else if (address) {
                    mapUrl = `https://waze.com/ul?q=${encodedAddress}&navigate=yes`;
                }
                break;
            case 'mapycz':
                if (latitude && longitude) {
                    mapUrl = `https://mapy.cz/?q=${latitude},${longitude}`;
                } else if (address) {
                    mapUrl = `https://mapy.cz/?q=${encodedAddress}`;
                }
                break;
            default:
                return;
        }

        if (mapUrl) { // Only open if a valid URL was generated
            window.open(mapUrl, '_blank');
            onClose();
        } else {
            console.warn("Could not generate map URL for:", mapApp, address, lat, lng);
            errorMessage('Chyba pri otvarani mapy.', enqueueSnackbar, closeSnackbar);
        }
    };

    let locationDescription = [];
    if (address) {
        locationDescription.push(address);
    }
    if (lat && lng) {
        locationDescription.push(`${lat.toFixed(5)}, ${lng.toFixed(5)}`);
    }


    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={onClose}
            aria-labelledby="map-selector-modal-title"
            aria-describedby="map-selector-modal-description"
        >
            <div className={classes.modalContent}>
                <Typography variant="h6" id="map-selector-modal-title">
                    Vyber mapu
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line' }} id="map-selector-modal-description" gutterBottom>
                    {locationDescription.join('\n')}
                </Typography>
                <Box display="flex" justifyContent="center">
                    <IconButton aria-label="Google Maps" className={classes.iconButton} onClick={() => handleMapAppClick('google')}>
                        <GoogleMapsIcon className={classes.mapIcon} />
                        <Typography className={classes.providerName}>Google Maps</Typography>
                    </IconButton>
                    <IconButton aria-label="Waze" className={classes.iconButton} onClick={() => handleMapAppClick('waze')}>
                        <WazeIcon className={classes.mapIcon} />
                        <Typography className={classes.providerName}>Waze</Typography>
                    </IconButton>
                    <IconButton aria-label="Mapy.cz" className={classes.iconButton} onClick={() => handleMapAppClick('mapycz')}>
                        <MapyCzIcon className={classes.mapIcon} />
                        <Typography className={classes.providerName}>Mapy.cz</Typography>
                    </IconButton>
                </Box>
            </div>
        </Modal>
    );
}