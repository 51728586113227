import React, {useState, Fragment} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import {addMinutes, format} from "date-fns";
import {TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import {Button, Slider, TextField} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {Loading} from "../../components";
import Grid from "@material-ui/core/Grid";
import {errorMessage, successMessage} from "../../utils/messages";
import Wrapper from "../../components/route/Wrapper";
import Content from "../../components/route/Content";
import {default as RouterBottomBar} from "../../components/route/BottomBar";
import {NavigateBefore, NavigateNext} from "@material-ui/icons";
import {Link, navigate, useLocation} from "@gatsbyjs/reach-router";
import {parse} from "query-string";
import RecalculationRoute from "../../datasource/recalculationRouteGraphQL";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const GET_ROUTE = loader('../../gql/query/route.graphql');
const CREATE_ROUTE = loader('../../gql/mutations/createRoute.graphql');
const UPDATE_ROUTE = loader('../../gql/mutations/updateRoute.graphql');

const useStyles = makeStyles(theme => ({
    code: {
        textAlign: 'center',
        fontSize: '20px',
        marginBottom: 20,
        marginLeft: 15,
    },
    radio: {
        margin: '5px 20px',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    spaceForMultiPanel: {
        marginBottom: 153,
    },
    wrapper: {
        textAlign: 'center',
        marginTop: -80,
    },
    floatRight: {
        marginLeft: 'auto',
        order: 2,
    },
    selectWrapper: {
        width: 184,
    }
}));

const marks = [
    {
        value: 0.8,
        label: 'pomalšie',
    },
    {
        value: 1,
        label: 'normálne',
    },
    {
        value: 1.2,
        label: 'rýchlejšie',
    },
];

const RouteStep1 = () => {
    const classes = useStyles();
    const location = useLocation();

    const httpParams = parse(location.search);
    let redirectToStep3 = false;
    if (httpParams && typeof httpParams.r !== "undefined" && httpParams.r === 'step3') {
        redirectToStep3 = true;
    }

    const [values, setValues] = useState({
        startDelivery: addMinutes(new Date(), 15),
        downtime: 2,
        speed: 1,
        routeStart: 'DepotPrievozska',
        routeEnd: 'DepotPrievozska',
    });
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [sendUpdateRoute, {loading: loadingMutation}] = useMutation(UPDATE_ROUTE, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Trasa uspešne aktualizovaná.', enqueueSnackbar, closeSnackbar);
            if (redirectToStep3) {
                navigate('/route/step3');
            } else {
                navigate('/route/step2');
            }
        },
    });

    const [sendCreateRoute, {loading: loadingCreateMutation}] = useMutation(CREATE_ROUTE, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Trasa uspešne vytvorená.', enqueueSnackbar, closeSnackbar);
            if (redirectToStep3) {
                navigate('/route/step3');
            } else {
                navigate('/route/step2');
            }
        },
    });

    const {data: dataRoute, loading} = useQuery(GET_ROUTE, {
        fetchPolicy: "network-only",
        variables: {
            date: format(values.startDelivery, 'yyyy-MM-dd'),
        },
        onCompleted: (data) => {
            if (data.lastRoute && !data.lastRoute?.isClosed) {
                const startDelivery = new Date(data.lastRoute.date + ' ' + data.lastRoute.startDelivery);

                setValues((prevState) => ({
                    ...prevState,
                    startDelivery: startDelivery,
                    downtime: data.lastRoute.downtime / 60,
                    speed: data.lastRoute.speed,
                    routeStart: data.lastRoute.routeStart,
                    routeEnd: data.lastRoute.routeEnd,
                }));
            }
        },
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
    });

    const handleDateChange = (datetime) => {
        setValues({...values, startDelivery: datetime});
    };

    const handleSlider = (event, newValue) => {
        setValues({...values, speed: newValue});
    };

    const handleChange = prop => event => {
        setValues({...values, [prop]: event.target.value});
    };

    const save = () => {
        let variables = {
            date: format(values.startDelivery, 'yyyy-MM-dd'),
            startDelivery: format(values.startDelivery, 'HH:mm:ssXXX'),
            downtime: Number.parseInt(values.downtime) * 60,
            speed: values.speed,
            routeStart: values.routeStart,
            routeEnd: values.routeEnd,
        };

        // for edit add id, for new route without id
        if (!dataRoute?.lastRoute?.isClosed && dataRoute?.lastRoute?.id) {
            sendUpdateRoute({
                variables: {
                    id: dataRoute.lastRoute.id,
                    synchro: redirectToStep3,
                    recalculation: redirectToStep3 ? RecalculationRoute.ONLY_ETA : RecalculationRoute.NO,
                    ...variables,
                },
            });
        } else {
            sendCreateRoute({
                variables,
            });
        }
    };

    return (
        <Fragment>
            <Wrapper>
                <Content>
                    <form autoComplete="on" noValidate>
                        {(loading || loadingMutation || loadingCreateMutation) && <Loading/>}
                        {!loading && !loadingMutation &&
                        <Grid container spacing={6}>
                            <Grid item sm={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        ampm={false}
                                        disablePast
                                        id="time-picker"
                                        mask="__:__"
                                        label="Štart doručovania"
                                        value={values.startDelivery}
                                        onChange={handleDateChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl className={classes.selectWrapper}>
                                    <InputLabel id="routeStart">Začiatok trasy</InputLabel>
                                    <Select
                                        label="Začiatok trasy"
                                        labelId="routeStart"
                                        value={values.routeStart}
                                        onChange={handleChange('routeStart')}
                                    >
                                        <MenuItem value="DepotPrievozska">Depo Prievozská</MenuItem>
                                        <MenuItem value="DepotKyjev">Depo Kyjev</MenuItem>
                                        <MenuItem value="Unipharma">Unipharma Budatinska 18</MenuItem>
                                        {/*<MenuItem value="Veglife">Veglife Dubravska cesta 14</MenuItem>*/}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl className={classes.selectWrapper}>
                                    <InputLabel id="routeEnd">Koniec trasy</InputLabel>
                                    <Select
                                        label="Koniec trasy"
                                        labelId="routeEnd"
                                        value={values.routeEnd}
                                        onChange={handleChange('routeEnd')}
                                    >
                                        <MenuItem value="DepotPrievozska">Depo Prievozská</MenuItem>
                                        <MenuItem value="DepotKyjev">Depo Kyjev</MenuItem>
                                        <MenuItem value="Virtual">Posledný bod na trase</MenuItem>
                                        <MenuItem value="Unipharma">Unipharma Budatinska 18</MenuItem>
                                        <MenuItem value="Cunovo">Čunovo</MenuItem>
                                        <MenuItem value="Vajnory">Vajnory</MenuItem>
                                        <MenuItem value="Biskupice">Biskupice</MenuItem>
                                        <MenuItem value="Devin">Devín</MenuItem>
                                        <MenuItem value="Devinska">Devínska</MenuItem>
                                        <MenuItem value="Zahorska">Záhorská</MenuItem>
                                        <MenuItem value="Raca">Rača</MenuItem>
                                        <MenuItem value="VeglifeEurovea">Veglife Eurovea</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    label="Počet minút na stope"
                                    type="number"
                                    value={values.downtime}
                                    InputProps={{
                                        inputProps: { min: 0 }
                                    }}
                                    error={values.downtime < 0}
                                    helperText={ values.downtime < 0 ? 'Minimum je 0 minut.' : '' }
                                    onChange={handleChange('downtime')}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Typography id="discrete-slider" gutterBottom>
                                    Individualy koeficient rychlosti kuriera
                                </Typography>
                                <Slider
                                    value={values.speed}
                                    onChange={handleSlider}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={0.05}
                                    marks={marks}
                                    min={0.7}
                                    max={1.3}
                                />
                            </Grid>
                        </Grid>
                        }

                        {/*                        <Fab onClick={save} color="primary"
                             style={{position: 'absolute', top: '4%', right: '6%', zIndex: 100}}
                             aria-label="scan">
                            <CloseIcon/>
                        </Fab>*/}
                    </form>
                </Content>
            </Wrapper>

            <RouterBottomBar>
                <Link to="/">
                    <Button
                        variant="contained"
                        startIcon={<NavigateBefore/>}
                        className={classes.floatRight}
                    >
                        Späť na zásielky
                    </Button>
                </Link>

                <Button
                    onClick={save}
                    variant="contained"
                    endIcon={<NavigateNext/>}
                    className={classes.floatRight}
                >
                    Ďalej
                </Button>
            </RouterBottomBar>
        </Fragment>
    );
};

export default RouteStep1;

