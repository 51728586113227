import React from "react";
import {Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        overflowX: 'hidden',
    },
}));

export default function Wrapper({children, className}) {
    const classes = useStyles();

    return (
        <Container className={clsx(classes.root, className)}>
            {children}
        </Container>
    );
};
